import React, { useEffect, useCallback, useState } from "react";
import classnames from "classnames";

import styles from "storefront/components/Collapsible/Collapsible.module.scss";

export type ChildProps = {
  isCollapsed: boolean;
  close: () => void;
  open: () => void;
  toggle: () => void;
};

type Props = {
  className?: string;
  collapsedClassName?: string;
  transitionDuration?: "slow" | "default" | "fast";
  baseClassName: string;
  isCollapsedByDefault?: boolean;
  style?: Record<string, string>;
  onChange?: (flag: boolean) => void;
  renderContent: (props: ChildProps) => React.ReactElement<unknown>;
  renderHeader: (props: ChildProps) => React.ReactElement<unknown>;
  toggleOnKeydown?: (event: KeyboardEvent) => boolean;
};

/**
 * @name Collapsible
 * @description A reusable component that takes in render props for both
 * a header as well as content.
 * We also have a ref here to control and get the height from the DOM
 * in order to animate it.
 */
const Collapsible = (props: Props) => {
  const [collapsibleContent, setCollapsibleContent] =
    useState<HTMLDivElement | null>(null);

  const collapsibleContentRef = useCallback((node: HTMLDivElement | null) => {
    if (node) setCollapsibleContent(node);
  }, []);

  /**
   * Height is explicity set to null here for mobile purposes.
   * In filters, we are rendering these collapsible components
   * but they are not actually collapsible. By setting null,
   * we are not passing down a height and will not by not
   * triggering events.
   */
  const [height, setHeight] = useState<(number | null | undefined) | "auto">(
    null,
  );

  const [isCollapsed, setIsCollapsed] = useState<boolean>(
    props.isCollapsedByDefault || false,
  );

  const change = (_isCollapsed: boolean) => {
    const tempHeight = _isCollapsed ? null : collapsibleContent?.scrollHeight;
    setHeight(tempHeight);
    setIsCollapsed(_isCollapsed);
    if (props.onChange) props.onChange(_isCollapsed);
  };

  const open = () => change(false);

  /**
   * Before closing, make sure we set the height back to an exact amount.
   */
  const close = () => {
    const tempHeight = collapsibleContent?.scrollHeight ?? null;
    setHeight(tempHeight);
    setTimeout(() => change(true), 0);
  };

  const toggle = () => (isCollapsed ? open() : close());

  const handleKeydown = (e: KeyboardEvent) => {
    if (props.toggleOnKeydown && props.toggleOnKeydown(e)) {
      toggle();
    }
  };

  useEffect(() => {
    /**
     * After transitioning from closed to open, set the height to auto.
     * This allows the content inside of the collapsible to change
     * without any height limitations.
     */
    const onTransitionEnd = () => {
      if (!isCollapsed) {
        setHeight("auto");
      }
    };

    if (collapsibleContent) {
      collapsibleContent.addEventListener("transitionend", onTransitionEnd);
    }

    return () => {
      collapsibleContent?.removeEventListener("transitionend", onTransitionEnd);
    };
  }, [collapsibleContent, isCollapsed]);

  useEffect(() => {
    if (props.toggleOnKeydown) {
      window.document.addEventListener("keydown", handleKeydown);
    }

    return () => {
      if (props.toggleOnKeydown) {
        window.document.removeEventListener("keydown", handleKeydown);
      }
    };
  });

  const formatHeight = (_height: number | "auto") =>
    _height === "auto" ? _height : `${_height}px`;

  const className = () =>
    classnames(props.baseClassName, {
      _collapsed: isCollapsed,
    });

  const { renderHeader, renderContent } = props;

  const childProps = {
    isCollapsed,
    open,
    close,
    toggle,
  };

  const style = props.style || {};
  const transitionDuration = props.transitionDuration || "default";

  return (
    <div className={className()} style={style}>
      {renderHeader(childProps)}
      <div
        className={classnames(styles.collapsibleContent, props.className, {
          [styles.slow]: transitionDuration === "slow",
          [styles.fast]: transitionDuration === "fast",
          [styles.collapsed]: isCollapsed,
          [props.collapsedClassName || ""]: isCollapsed,
        })}
        ref={collapsibleContentRef}
        style={
          height
            ? {
                height: formatHeight(height),
              }
            : {}
        }
      >
        {renderContent(childProps)}
      </div>
    </div>
  );
};

export default Collapsible;
