import React, { useEffect } from "react";
import cn from "classnames";
import useAnalytics from "storefront/hooks/useAnalytics";
import FlashManager from "storefront/lib/flash/FlashManager";
import { requestWatcher } from "storefront/GrailedAPI/request/request";
import SiteBanner from "storefront/components/SiteBanner";
import SuspenseWithFragment from "storefront/components/SuspenseWithFragment";
import ViolationModal from "storefront/components/ViolationModal";
import {
  Authenticated,
  Unauthenticated,
  Failed,
} from "storefront/Authentication";
import useFeatureFlag from "storefront/hooks/data/useFeatureFlag";
import { Feature } from "storefront/GrailedAPI/v1/Users/Flipper/Feature";
import ContentfulHomepage from "./ContentfulHomepage";
import LoadingHomepageContent from "./ContentfulHomepage/LoadingHomepageContent";
import styles from "./index.module.scss";

type Props = {
  auth: Authenticated | Unauthenticated | Failed;
};

const Homepage = ({ auth }: Props) => {
  const { track } = useAnalytics();

  useEffect(() => {
    const flashManager = FlashManager.getInstance();
    const unsubscribe = requestWatcher.subscribe((requestKey) => {
      flashManager.notice(`${requestKey}:  Too many requests!`);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    track({
      object: "homepage",
      action: "viewed",
      properties: {
        nonInteraction: 1,
      },
    });
  }, [track]);

  const homepageV1Flag =
    auth.type === "Authenticated"
      ? Feature.NEW_HOMEPAGE_LOGGED_IN
      : Feature.NEW_HOMEPAGE_LOGGED_OUT;

  const homepageV1EnabledResource = useFeatureFlag(homepageV1Flag);
  const homepageV2EnabledResource = useFeatureFlag(Feature.NEW_HOMEPAGE_V2);

  if (
    homepageV1EnabledResource.type !== "Completed" ||
    homepageV2EnabledResource.type !== "Completed"
  )
    return <LoadingHomepageContent />;

  let contentfulEntryName;

  if (homepageV2EnabledResource.value) {
    contentfulEntryName = "homepageVariantV2";
  } else if (homepageV1EnabledResource.value) {
    contentfulEntryName = "homepageVariant";
  } else {
    contentfulEntryName = "homepage";
  }

  return (
    <div id="homepage-v2">
      <div id="siteBanner">
        <SiteBanner />
      </div>

      <ViolationModal />

      <div className="homepage-wrapper">
        <SuspenseWithFragment>
          <div
            className={cn({
              [styles.homepageV2]: contentfulEntryName === "homepageVariantV2",
            })}
          >
            <ContentfulHomepage contentfulEntryName={contentfulEntryName} />
          </div>
        </SuspenseWithFragment>
      </div>
    </div>
  );
};

export default Homepage;
